import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import cookie from 'cookie'
import { jwtDecode } from 'jwt-decode';

export const calculateTotalsForPartnerUSD = (data, conversionRate) => {
    let totalProfit = 0;
    let totalUsers = 0;
    let totalProfitINR = 0;

    data && data?.forEach(item => {
        totalProfit += item.total_amount;
        totalUsers += item.total_sales;
    });

    // Convert total amount to INR and round the values
    totalProfit = Math.round(totalProfit);
    totalProfitINR = Math.round(totalProfit * conversionRate);
    totalUsers = Math.round(totalUsers);

    return {
        totalProfit,
        totalProfitINR,
        totalUsers
    };
};
export const calculateTotalsForUSD = (data, conversionRate) => {
    let totalProfit = 0;
    let totalUsers = 0;
    let totalProfitINR = 0;

    data && data?.forEach(item => {
        totalProfit += item.total_amount;
        totalUsers += item.total_sales;
    });

    // Convert total amount to INR and round the values
    totalProfit = Math.round(totalProfit);
    totalProfitINR = Math.round(totalProfit * conversionRate);
    totalUsers = Math.round(totalUsers);

    return {
        totalProfit,
        totalProfitINR,
        totalUsers
    };
};

export const calculateTotalsForINR = (data) => {
    let totalRevenue = 0;
    let totalUsers = 0;

    data && data.forEach(item => {
        totalRevenue += item.total_amount;
        totalUsers += item.total_sales;
    });

    // Convert total amount to INR and round the values
    totalRevenue = Math.round(totalRevenue);
    totalUsers = Math.round(totalUsers);

    // Calculate GST (18/118 of totalRevenue)
    const gstAmount = Math.round((totalRevenue * 18) / 118);
    const totalProfit = totalRevenue - gstAmount;

    return {
        totalRevenue,
        totalUsers,
        gstAmount,
        totalProfit
    };
};

export function getISTDateTime() {
    const now = new Date();
  
    // Convert to IST time
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
  
    const istDate = new Intl.DateTimeFormat('en-GB', options).format(now);
  
    // Split the date and time
    const [datePart, timePart] = istDate.split(', ');
  
    // Reformat date as YYYY-MM-DD
    const formattedDate = datePart.split('/').reverse().join('-');
  
    // Reformat time to HH:mm:ss
    const formattedTime = timePart;
  
    // Combine date and time
    return `${formattedDate} ${formattedTime}`;
  }
  
export function formatCurrency(value) {
    if (value === undefined || value === null) {
        return 'N/A'; // or handle as needed
    }
    
    if (typeof value !== 'number') {
        return 'Invalid'; // or handle as needed
    }

    if (value >= 1e7) { // 1 Crore = 10,000,000
        return (value / 1e7).toFixed(2) + ' Cr';
    } else if (value >= 1e5) { // 1 Lakh = 100,000
        return (value / 1e5).toFixed(2) + ' L';
    } else if (value >= 1e3) { // 1 Thousand = 1,000
        return (value / 1e3).toFixed(2) + ' K';
    } else {
        return value.toString(); // For values less than 1,000
    }
}

export function convertToNumeric(data) {
    const result = {};
    
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            let value = data[key];
            // Remove non-numeric characters
            let numericValue = parseFloat(value?.replace(/[^0-9.]/g, ''));
            // Determine the unit and adjust the value accordingly
            if (value.includes('Cr')) {
                numericValue *= 1e7; // 1 Cr = 10 million
            } else if (value.includes('L')) {
                numericValue *= 1e5; // 1 L = 100 thousand
            } else if (value.includes('K')) {
                numericValue *= 1e3; // 1 K = 1 thousand
            }
            // Convert to integer
            result[key] = Math.round(numericValue); // or use Math.floor(numericValue) if you want to round down
        }
    }
    
    return result;
}

export const formatThousands = (value) => Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 3,
    notation: 'compact',
}).format(value);

export function isObjectEmpty(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}
export function getEmailFromToken(token) {
    try {

        const decoded = jwtDecode(token);

        return decoded.email;
    } catch (error) {
        return null;
    }
}


export function isEmptyString(value) {
    return typeof value === 'string' && value.trim() === '';
}

export function isArrayEmpty(arr) {
    // Check if the array is empty
    if (arr === undefined || arr.length === 0) {
        return true; // Array is empty
    } else {
        return false; // Array is not empty
    }
}

export function mergeTopics(...sheets) {
    // Extract topics from each sheet
    const allTopics = sheets.flatMap(sheet => {
        if (sheet.sheetData) {
            // For sheets similar to sdeSheet
            return sheet.sheetData.flatMap(entry => entry.topics);
        } else if (sheet.sub_steps) {
            // For sheets similar to a2zSheet
            return sheet.sub_steps.flatMap(step => step.topics);
        } else if (sheet.sheetData) {
            // For sheets similar to updated a2zSheet
            return sheet.sheetData.flatMap(entry => entry.sub_steps.flatMap(step => step.topics));
        }
        return [];
    });

    return allTopics;
}

export function mergeA2ZTopics(a2zSheet) {
    // if (a2zSheet?.sheetData) {
    // If a2zSheet structure is similar to sdeSheet
    return a2zSheet?.sheetData.flatMap(entry => entry?.sub_steps.flatMap(step => step?.topics));
    // } else if (a2zSheet?.sub_steps) {
    // If a2zSheet structure is similar to itself
    // return a2zSheet?.sub_steps.flatMap(step => step?.topics);
    // }
    // return [];
}

export function mergeArrays(arr1, arr2) {
    return arr1.concat(arr2);
}

export function createSavedNotes(notes, allTopics) {
    const savedNotes = [];

    for (const noteKey in notes) {
        const matchingTopic = allTopics?.find(topic => topic?.id === noteKey);

        if (matchingTopic && notes[noteKey]?.trim() !== '') {
            // Create a new object with the same properties and add the 'note' property
            const topicWithNote = { ...matchingTopic, note: notes[noteKey] };
            savedNotes.push(topicWithNote);
        }
    }

    return savedNotes;
}

export function mergeTopicsA2Z(object) {
    if (!object || !object?.sub_steps) {
        console.error("Invalid object format");
        return [];
    }

    const mergedArray = [];

    object?.sub_steps?.forEach((subStep) => {
        if (subStep?.topics) {
            mergedArray.push(...subStep.topics);
        }
    });

    return mergedArray;
}

export function extractYouTubeVideoId(youtubeLink) {
    // Regular expression to match the YouTube video ID in the longer link
    const longLinkRegex = /[?&]v=([^&]+)/;

    // Regular expression to match the YouTube video ID in the short link
    const shortLinkRegex = /youtu\.be\/([^&]+)/;

    // Use the regular expressions to extract the video ID
    const longLinkMatch = youtubeLink?.match(longLinkRegex);
    const shortLinkMatch = youtubeLink?.match(shortLinkRegex);

    // Check if a match is found in the long link
    if (longLinkMatch && longLinkMatch[1]) {
        return longLinkMatch[1];
    }
    // Check if a match is found in the short link
    else if (shortLinkMatch && shortLinkMatch[1]) {
        return shortLinkMatch[1];
    } else {
        // If no match is found, handle the error or return null
        // console.error('Invalid YouTube link');
        return null;
    }
}


export const useArraySizeChanged = (inputArray) => {
    const { progress } = useSelector((state) => state.profile);

    const isSizeChanged = () => inputArray.length !== progress.length;

    return isSizeChanged();
};


export const findObject = (data, decodedStepTitle, decodedQuestionTitle) => {
    // Find the matching object
    const result = data
        ?.flatMap((step) =>
            step?.sub_steps?.flatMap((sub) =>
                sub?.topics?.filter((topic) =>
                    topic?.step_title?.toLowerCase() === decodedStepTitle?.toLowerCase() &&
                    topic?.question_title?.toLowerCase() === decodedQuestionTitle?.toLowerCase()
                )
            )
        )
        .flat();

    return result?.length > 0 ? result[0] : undefined;
};


export function hasHtmlTags(inputString) {
    const htmlRegex = /<[a-z][\s\S]*>/i;
    return htmlRegex.test(inputString);
}

export function isLastObjectInArray(obj, array) {
    // Check if the array is not empty
    if (Array.isArray(array) && array.length > 0) {
        // Compare the given object with the last object in the array
        return obj === array[array.length - 1];
    }
    // If the array is empty, the object cannot be the last one
    return false;
}

export function hasOnlyOneObject(arr) {
    // Check if the input is an array and has exactly one element
    return Array.isArray(arr) && arr.length === 1 && typeof arr[0] === 'object';
}

export const TOOLBAR_OPTIONS_NOTES = [
    [{ 'font': [] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'color': [] }],
    [{ 'align': [] }]
];

export const TOOLBAR_OPTIONS_DISCUSSION = [
    ['bold', 'italic', 'underline'],
    ['blockquote', 'code-block']
];
export const TOOLBAR_OPTIONS_DRAFT = [
    ['bold', 'italic', 'underline'],
    ['blockquote', 'code-block'],
    ["link", "image"]
];

export const getTimestamp = (createdAt) => {
    const now = new Date();
    const createdAtDate = new Date(createdAt);
    let secondsDiff = Math.floor((now - createdAtDate) / 1000);

    secondsDiff = Math.max(secondsDiff, 0);
    // Define time units in seconds
    const minute = 60;
    const hour = 60 * minute;
    const day = 24 * hour;
    const week = 7 * day;
    const month = 30 * day;
    const year = 365 * day;

    // Calculate time difference
    if (secondsDiff < minute) {
        return `${secondsDiff} second${secondsDiff !== 1 ? 's' : ''} ago`;
    } else if (secondsDiff < hour) {
        const minutes = Math.floor(secondsDiff / minute);
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (secondsDiff < day) {
        const hours = Math.floor(secondsDiff / hour);
        return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (secondsDiff < week) {
        const days = Math.floor(secondsDiff / day);
        return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (secondsDiff < month) {
        const weeks = Math.floor(secondsDiff / week);
        return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
    } else if (secondsDiff < year) {
        const months = Math.floor(secondsDiff / month);
        return `${months} month${months !== 1 ? 's' : ''} ago`;
    } else {
        const years = Math.floor(secondsDiff / year);
        return `${years} year${years !== 1 ? 's' : ''} ago`;
    }
};

export function sortRepliesByTime(data) {
    // Function to sort replies based on time
    const sortRepliesByTime = (replies) => {
        return [...replies]?.sort((a, b) => new Date(a.time) - new Date(b.time));
    };

    // Create a new array to store sorted data
    const sortedData = data && data?.map(entry => ({
        ...entry,
        replies: sortRepliesByTime(entry.replies)
    }));

    return sortedData;
}

export const formatAndDivideNumber = (num) => {
    if (num >= 1000000) {
        const formattedNum = (num / 1000000).toFixed(1)
        return `${formattedNum} M`
    } else if (num >= 1000) {
        const formattedNum = (num / 1000).toFixed(1)
        return `${formattedNum} K`
    } else {
        return num.toString()
    }
}

export function hasAcceptedReply(data) {
    if (data && data?.replies && Array.isArray(data?.replies)) {
        // Check if any reply has isAccepted = 1
        return data?.replies?.some(reply => reply.isAccepted === 1);
    }

    // Return false if data or replies are not present
    return false;
}

export function mergeObjects(obj1, obj2) {
    return { ...obj1, ...obj2 };
}


export const fetchLeetCodeData = async (username) => {
    const query = `
        query getUserProfile($username: String!) {
            allQuestionsCount {
                difficulty
                count
            }
            matchedUser(username: $username) {
                contributions {
                    points
                }
                profile {
                    reputation
                    ranking
                }
                submissionCalendar
                submitStats {
                    acSubmissionNum {
                        difficulty
                        count
                        submissions
                    }
                    totalSubmissionNum {
                        difficulty
                        count
                        submissions
                    }
                }
            }
            recentSubmissionList(username: $username) {
                title
                titleSlug
                timestamp
                statusDisplay
                lang
                __typename
            }
            matchedUserStats: matchedUser(username: $username) {
                submitStats: submitStatsGlobal {
                    acSubmissionNum {
                        difficulty
                        count
                        submissions
                        __typename
                    }
                    totalSubmissionNum {
                        difficulty
                        count
                        submissions
                        __typename
                    }
                    __typename
                }
            }
        }
    `;
    const formatData = (data) => {
        let sendData = {
            totalSolved: data.matchedUser.submitStats.acSubmissionNum[0].count,
            totalSubmissions: data.matchedUser.submitStats.totalSubmissionNum,
            totalQuestions: data.allQuestionsCount[0].count,
            easySolved: data.matchedUser.submitStats.acSubmissionNum[1].count,
            totalEasy: data.allQuestionsCount[1].count,
            mediumSolved: data.matchedUser.submitStats.acSubmissionNum[2].count,
            totalMedium: data.allQuestionsCount[2].count,
            hardSolved: data.matchedUser.submitStats.acSubmissionNum[3].count,
            totalHard: data.allQuestionsCount[3].count,
            ranking: data.matchedUser.profile.ranking,
            contributionPoint: data.matchedUser.contributions.points,
            reputation: data.matchedUser.profile.reputation,
            submissionCalendar: JSON.parse(data.matchedUser.submissionCalendar),
            recentSubmissions: data.recentSubmissionList,
            matchedUserStats: data.matchedUser.submitStats
        }
        return sendData;
    }

    try {
        const response = await fetch('https://leetcode.com/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Referer': 'https://leetcode.com'
            },
            body: JSON.stringify({ query, variables: { username } }),
        });

        const data = await response.json();

        if (data.errors) {
            return null;
        }

        return formatData(data.data);
    } catch (error) {
        toast.error("Unable to Fetch Leetcode data")
    }
};
export const deleteCookie = (name) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() - (30 * 24 * 60 * 60 * 1000));

    document.cookie = `${name}=; expires=${expirationDate.toUTCString()}; path=/`;
}


export const getCookie = (name) => {
    const cookies = cookie.parse(document.cookie)
    const value = cookies && cookies[name]

    if (value === undefined) {
        // Handle the case where the cookie doesn't exist
        return undefined; // or any other appropriate value or behavior
    }

    const hasToParse = Boolean((value && value[0] === '{') || value[0] === '[')

    return hasToParse ? JSON.parse(value) : value
}

export const setCookie = (name, value) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + (30 * 24 * 60 * 60 * 1000));

    const cookieValue = typeof value === 'object' ? JSON.stringify(value) : String(value);

    document.cookie = `${name}=${cookieValue}; expires=${expirationDate.toUTCString()}; path=/`;
}


export function renameKeys(obj) {
    const keyMapping = {
        "arr": "Array",
        "dp": "DP",
        "graph": "Graph",
        "twopoint": "Two Pointer",
        "recur": "Recursion",
        "impl": "Implementation",
        "bs": "Binary Search",
        "sde_sheet": "SDE",
        "a2z_sheet": "A2Z",
        "sheet_79": "79"
    };

    const renamedObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const newKey = keyMapping[key];
            renamedObj[newKey] = obj[key];
        }
    }

    return renamedObj;
}

export function getRandomProblem(topicsData, topic, level) {
    // Assuming renamedObj is accessible here (the object with renamed keys)
    const topics = Object.keys(topicsData);

    // Check if the specified topic exists
    if (!topics.includes(topic)) {
        return null;
    }

    // Check if the specified level exists for the given topic
    const levels = Object.keys(topicsData[topic]);
    const lowercaseLevel = level.toLowerCase(); // Convert to lowercase
    if (!levels.includes(lowercaseLevel)) {
        return null;
    }

    // Retrieve the array of problems for the specified topic and level
    const problems = topicsData[topic][lowercaseLevel];

    // Check if there are any problems available for the specified topic and level
    if (problems.length === 0) {
        return null;
    }

    // Select a random problem from the array
    const randomIndex = Math.floor(Math.random() * problems.length);
    return problems[randomIndex];
}

export const sortByLengthWithWhitespace = (a, b) => {
    // Remove whitespace and compare lengths
    const lengthA = a.replace(/\s/g, '')?.length;
    const lengthB = b.replace(/\s/g, '')?.length;

    // Compare lengths
    if (lengthA === lengthB) {
        // If lengths are equal, use default string comparison
        return a?.localeCompare(b);
    } else {
        // Otherwise, sort by length
        return lengthA - lengthB;
    }
};

export function formatDate(dateString) {
    const months = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];

    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
}

export const convertSlugToReadable = (slug) => {
    // Split the slug by hyphens and capitalize each word
    const words = slug.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1));
    // Join the words with spaces
    return words.join(' ');
};

export function truncateString(str) {
    if (str.length <= 35) {
        return str; // Return the original string if its length is less than or equal to 20
    } else {
        return str.slice(0, 35) + '...'; // Truncate the string to 20 characters and append '...'
    }
}

export function extractHrefs(playlist) {
    const hrefs = [];
    for (const item of playlist) {
        hrefs.push(item.href);
    }
    return hrefs;
}

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export const preprocessUrl = (url) => {
    // Add "https://" if missing from the beginning of the URL
    if (url && !url.startsWith("https://") && !url.startsWith("http://")) {
        return "https://" + url;
    }
    return url;
};

export function capitalizeAndRemoveHyphens(str) {
    // Split the string by hyphens and capitalize each word
    const words = str.split('-').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the words back into a single string with spaces
    const formattedStr = words.join(' ');

    // If there are consecutive capital letters, insert spaces before each capital letter except the first one
    const result = formattedStr.replace(/([A-Z])/g, ' $1').trim();

    return result;
}

export function mergeCPData(dataArray) {

    const mergedData = [];

    // Iterate through the array of objects
    dataArray && dataArray?.forEach(obj => {
        // Concatenate the data arrays
        mergedData?.push(...obj?.data);
    });

    return mergedData;
}

export function setObjectInCookie(cookieName, objectValue, expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);

    // Convert the object to a JSON string
    const jsonString = JSON.stringify(objectValue);

    // Encode the cookie name and value, and set the cookie
    const cookieString = `${encodeURIComponent(cookieName)}=${encodeURIComponent(jsonString)}; expires=${expirationDate.toUTCString()}; path=/`;

    document.cookie = cookieString;
}

export function getObjectFromCookie(cookieName) {
    const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(`${encodeURIComponent(cookieName)}=`));

    if (cookieValue) {
        // Decode the cookie value and parse the JSON string to get the object
        const jsonString = decodeURIComponent(cookieValue.split('=')[1]);
        return JSON.parse(jsonString);
    }

    return null; // Return null if the cookie is not found
}

export function transformTAData(inputData) {
    let transformedData = [];

    // Iterate over each email
    for (let email in inputData) {
        if (inputData?.hasOwnProperty(email)) {
            let emailData = {
                "email": email,
                "data": inputData[email]
            };
            transformedData.push(emailData);
        }
    }

    return transformedData;
}
export function convertAndSortReplies(data, localTimeZone) {
    // Function to convert UTC timestamp to local time
    function convertToLocale(utcTimestamp) {
        const utcDate = new Date(utcTimestamp);
        return new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000); // Add timezone offset
    }

    // Check if data is an array
    if (Array.isArray(data)) {
        // Map through each comment object
        return data.map(comment => {
            // Create a new object with modified properties
            return {
                ...comment,
                comment_timestamp: convertToLocale(comment.comment_timestamp),
                replies: comment.replies.map(reply => ({
                    ...reply,
                    time: convertToLocale(reply.time)
                })).sort((a, b) => a.time - b.time)
            };
        });
    } else {
        console.error('Input data is not in the expected format.');
        return data;
    }
}
export function adjustToUTC(dateStr, isEndDate) {
    const date = new Date(dateStr);
    if (isEndDate) {
        // Set to 18:29:59 for end date without changing the day
        date.setUTCHours(18, 29, 59, 999);
    } else {
        // Set to 18:30:00 for start date (previous day)
        date.setDate(date.getDate() - 1);
        date.setUTCHours(18, 30, 0, 0);
    }
    return date.toISOString().replace("T", " ").slice(0, 19);
}
